<template>
  <el-container class="container">
    <el-container class="main-container">
      <el-header class="header" height="2.70833vw">
        <div class="header-left">
          <div class="header-title">
            <div class="header-title-icon" />
            活动推送
          </div>
          <!-- <div class="header-btn">活动推送</div> -->
        </div>
        <div class="header-right">
          <i
            class="icon"
            @click="toggleSelection(tableData), (icon = false)"
            v-if="icon"
          />
          <i
            class="icon box1"
            @click="toggleSelection(tableData), (icon = true)"
            v-if="!icon"
          />
          <el-button @click="toggleSelection(tableData), (icon = !icon)"
            >全选</el-button
          >
          <el-button @click="addDelClick">删除</el-button>
          <el-button style="width: 136px" @click="addReadClick"
            >全部已读</el-button
          >
        </div>
      </el-header>
      <el-main>
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 2vh"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="74"> </el-table-column>
          <el-table-column
            label="标题内容"
            min-width="60%"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div @click="detailClick(scope.row)" style="cursor: pointer">
                <span v-if="scope.row.status"
                  ><div class="yuan red" />
                  {{ scope.row.title }}</span
                >
                <span v-else style="color: #999999"
                  ><div class="yuan" />
                  {{ scope.row.title }}</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="time" label="发送时间" min-width="25%">
          </el-table-column>
          <el-table-column label="操作" min-width="15%">
            <template slot-scope="scope">
              <del-btn
                style="color: #333333"
                :req="$service.api.del"
                :list="tableData"
                :params="{ id: scope.row.id }"
                :index="scope.$index"
              ></del-btn>

              <!-- <span @click="delClick(scope.row)" style="cursor: pointer"
                >删除</span
              > -->
            </template>
          </el-table-column>
        </el-table>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import DelBtn from '@/components/workbench/DelBtn.vue';
/* eslint-disable */
export default {
  components: { DelBtn },
  name: 'Message',
  data() {
    return {
      tableData: [
        {
          id: '10001',
          title: '【系统公告】 longmap平台3.0.0功能发布',
          time: '2022-7-8 16:00:00',
          status: false
        },
        {
          id: '10002',
          title: '活动推送主标题',
          time: '2022-7-8 16:00:00',
          status: true
        },
        {
          id: '10003',
          title: '活动推送主标题',
          time: '2022-7-8 16:00:00',
          status: true
        }
      ],
      icon: true,
      multipleSelection: [],
      ArrSelection: [],
      archivesArr: []
    };
  },
  created() {},
  methods: {
    //全部已读
    addReadClick() {
      this.$message.error('请求错误');
    },
    // 详情
    detailClick(row) {
      let data = {
        id: row.id,
        title: row.title,
        status: true
      };
      this.$store.commit('userData/setMessageDetailId', data);
    },
    // 删除
    delClick(row) {
      this.$message.error('删除失败');
    },
    // 多选删除
    addDelClick() {
      if (this.ArrSelection == '') {
        this.$message('请选择您要删除的内容');
      } else {
        this.$confirm('删除后不能恢复，确定要删除吗？', '删除确认', {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'del'
        })
          .then(() => {
            this.$message.error('请求错误');
          })
          .catch(() => {});
      }
    },
    // 全选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    //多选数据
    handleSelectionChange(val) {
      this.multipleSelection = val;
      //储存批量删除名称的数组
      this.archivesArr = [];
      val.forEach(item => {
        this.archivesArr.push(item.fileName);
      });
      var arr = [];
      val.forEach((item, i) => {
        arr.push(item.id);
      });
      this.ArrSelection = arr;
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  border-radius: 0 !important;
  padding: 0 !important;
  background-color: transparent !important;
  .main-container {
    border-radius: 10px;
    padding: 20px 28px;
    background-color: #fff;
  }
}
//圆点
.yuan {
  margin-right: 5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #dcdcdc;
  border-radius: 50%;
}
.red {
  background: #ff3f2b;
}

/deep/.header-right {
  font-size: 20px;
  .el-button {
    width: 60px;
    height: 42px;
    color: #333333;
    font-size: 20px;
    border: none;
    background: #fff;
    border-radius: 12px;
    &:hover {
      background: #eff3fb;
      border-radius: 12px;
      color: #507cff;
    }
  }
}
.icon {
  z-index: 0;
  cursor: pointer;
  height: 22px;
  width: 22px;
  background-image: url(~a/workbench/box.png);
}
.box1 {
  background-image: url(~a/workbench/box1.png);
}
</style>
